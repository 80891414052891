import styled from 'styled-components';

export const Footer = styled.div`
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/bpk-styles.ad96fe6e.chunk.css */
  footer {
    display: block;
  }
  a {
    -webkit-text-decoration-skip: objects;
    background-color: initial;
  }
  a:active,
  a:hover {
    outline-width: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  button {
    font: inherit;
    margin: 0;
  }
  button {
    overflow: visible;
  }
  button {
    text-transform: none;
  }
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  .BpkText_bpk-text__MjhhY {
    margin: 0 auto;
    text-align: center !important;
  }
  .Copyright_copyright__container__ODRlO {
    text-align: center !important;
  }
  .BpkText_bpk-text--sm__NGI3M {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .BpkText_bpk-text--label-2__NzNlM {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
  }
  .BpkButton_bpk-button__OTE4Z {
    background-color: #05203c;
    background-color: var(--bpk-button-primary-background-color, #05203c);
    border: 0;
    border-radius: 0.5rem;
    color: #fff;
    color: var(--bpk-button-primary-text-color, #fff);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 0;
    min-height: 2.25rem;
    padding: 0.375rem 1rem;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
  }
  .bpk-no-touch-support
    .BpkButton_bpk-button__OTE4Z:hover:not(:active):not(:disabled) {
    background-color: #154679;
    background-color: var(--bpk-button-primary-hover-background-color, #154679);
    color: #fff;
    color: var(--bpk-button-primary-hover-text-color, #fff);
  }
  .BpkButton_bpk-button__OTE4Z:active {
    background-color: #154679;
    background-color: var(
      --bpk-button-primary-active-background-color,
      #154679
    );
    color: #fff;
    color: var(--bpk-button-primary-active-text-color, #fff);
  }
  .BpkButton_bpk-button__OTE4Z:disabled {
    background-color: #e0e4e9;
    color: #0003;
    cursor: not-allowed;
  }
  .BpkButton_bpk-button--link__ZDEyO {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background-color: #0000;
    border: 0;
    box-shadow: none;
    color: var(--bpk-link-color, #0062e3);
    color: #0062e3;
    cursor: pointer;
    padding: 0.375rem 0;
    text-decoration: none;
  }
  .bpk-no-touch-support
    .BpkButton_bpk-button--link__ZDEyO:hover:not(:active):not(:disabled) {
    color: #0062e3;
    color: var(--bpk-link-hover-color, #0062e3);
    text-decoration: underline;
  }
  .BpkButton_bpk-button--link__ZDEyO:visited {
    color: #0062e3;
    color: var(--bpk-link-visited-color, #0062e3);
  }
  .BpkButton_bpk-button--link__ZDEyO:active {
    color: #0062e3;
    color: var(--bpk-link-active-color, #0062e3);
    text-decoration: underline;
  }
  .BpkButton_bpk-button--link__ZDEyO:active,
  .bpk-no-touch-support
    .BpkButton_bpk-button--link__ZDEyO:hover:not(:active):not(:disabled) {
    background: none;
    color: #024daf;
    text-decoration: none;
  }
  .BpkButton_bpk-button--link__ZDEyO:disabled {
    background: none;
    color: #0003;
    text-decoration: none;
  }
  .BpkButton_bpk-button--secondary-on-dark__ZWNkY {
    background-color: #ffffff1a;
    background-color: var(
      --bpk-button-secondary-on-dark-background-color,
      #ffffff1a
    );
    color: #fff;
    color: var(--bpk-button-secondary-on-dark-text-color, #fff);
  }
  .bpk-no-touch-support
    .BpkButton_bpk-button--secondary-on-dark__ZWNkY:hover:not(:active):not(
      :disabled
    ) {
    background-color: #010913;
    background-color: var(
      --bpk-button-secondary-on-dark-hover-background-color,
      #010913
    );
    color: #fff;
    color: var(--bpk-button-secondary-on-dark-hover-text-color, #fff);
  }
  .BpkButton_bpk-button--secondary-on-dark__ZWNkY:active {
    background-color: #010913;
    background-color: var(
      --bpk-button-secondary-on-dark-active-background-color,
      #010913
    );
    color: #fff;
    color: var(--bpk-button-secondary-on-dark-active-text-color, #fff);
  }
  .BpkButton_bpk-button--secondary-on-dark__ZWNkY:disabled {
    background-color: #0b121d;
    color: #fff3;
  }
  .BpkButton_bpk-button--full-width__YjkwY {
    display: block;
    width: 100%;
  }
  .BpkButton_bpk-button__OTE4Z span > svg {
    display: block;
  }
  .BpkButton_bpk-button__OTE4Z svg {
    fill: currentcolor;
  }
  .BpkLink_bpk-link__MmQwY {
    -webkit-appearance: none;
    appearance: none;
    background-color: #0000;
    border: 0;
    color: #0062e3;
    color: var(--bpk-link-color, #0062e3);
    cursor: pointer;
    padding: 0;
    text-decoration: none;
  }
  .bpk-no-touch-support
    .BpkLink_bpk-link__MmQwY:hover:not(:active):not(:disabled) {
    color: #0062e3;
    color: var(--bpk-link-hover-color, #0062e3);
    text-decoration: underline;
  }
  .BpkLink_bpk-link__MmQwY:visited {
    color: #0062e3;
    color: var(--bpk-link-visited-color, #0062e3);
  }
  .BpkLink_bpk-link__MmQwY:active {
    color: #0062e3;
    color: var(--bpk-link-active-color, #0062e3);
    text-decoration: underline;
  }
  .BpkLink_bpk-link--alternate__ZmYxY {
    color: #fff;
    color: var(--bpk-link-alternate-color, #fff);
  }
  .bpk-no-touch-support
    .BpkLink_bpk-link--alternate__ZmYxY:hover:not(:active):not(:disabled) {
    color: #fff;
    color: var(--bpk-link-alternate-hover-color, #fff);
  }
  .BpkLink_bpk-link--alternate__ZmYxY:visited {
    color: #fff;
    color: var(--bpk-link-alternate-visited-color, #fff);
  }
  .BpkLink_bpk-link--alternate__ZmYxY:active {
    color: #fff;
    color: var(--bpk-link-alternate-active-color, #fff);
  }
  .BpkAccordion_bpk-accordion__M2FiY {
    margin: 0;
  }
  .BpkAccordion_bpk-accordion--on-dark__MDMwN {
    color: #fff;
  }
  .BpkAccordionItem_bpk-accordion__title__ZTg3Z {
    height: auto;
  }
  .BpkAccordionItem_bpk-accordion__toggle-button__MGVlM {
    -webkit-appearance: none;
    appearance: none;
    background-color: #0000;
    border: 0;
    color: #161616;
    cursor: pointer;
    padding: 0;
    text-align: left;
    width: 100%;
  }
  .BpkAccordionItem_bpk-accordion__flex-container__MzM5O {
    display: inline-flex;
    flex-direction: row;
    margin: 1rem 0;
    width: 100%;
  }
  .BpkAccordionItem_bpk-accordion__title-text__OTZkY {
    flex-grow: 1;
  }
  .BpkAccordionItem_bpk-accordion__title-text--on-dark__ZWExN {
    color: #fff;
  }
  .BpkAccordionItem_bpk-accordion__icon-wrapper__MGUzM {
    display: inline-block;
  }
  .BpkAccordionItem_bpk-accordion__item-expand-icon__NWYxN {
    fill: #161616;
  }
  .BpkAccordionItem_bpk-accordion__item-expand-icon--on-dark__OWJiM {
    fill: #fff;
  }
  .BpkAccordionItem_bpk-accordion__content-container__YmFhO {
    margin: 0;
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/HomepageFooter.d91cf51a.chunk.css */
  .CultureSelectorButton_cultureSelectorText__NTM3Z {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CultureSelectorButton_currencyLabel__OTk5Y {
    color: #fff;
  }
  .CultureSelectorButton_cultureSelectorSeparator__OTFkM {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .CultureSelectorButton_button__NzVjY {
    align-items: center;
    display: flex;
    text-align: left;
    width: 100%;
    width: fit-content !important;
  }
  @media (min-width: 64.0625rem) {
    .CultureSelectorButton_button__NzVjY {
      max-width: 18rem;
    }
  }
  .Footer_footer__OWMwM {
    background-color: #05203c;
    color: #eff3f8;
  }
  @media (min-width: 64.0625rem) {
    .Footer_footer__OWMwM {
      padding: 0 3rem;
    }
  }
  @media (min-width: 32.0625rem) and (max-width: 64rem) {
    .Footer_footer__OWMwM {
      padding: 0 2rem;
    }
  }
  @media (max-width: 32rem) {
    .Footer_footer__OWMwM {
      padding: 0 1.5rem;
    }
  }
  .Footer_footer__OWMwM .Footer_footer__link-container__ODNlM {
    color: #eff3f8;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 76.5rem;
    padding-top: 1.5rem;
  }
  @media (min-width: 64.0625rem) {
    .Footer_footer__OWMwM .Footer_footer__link-container__ODNlM {
      grid-column-gap: 1.5rem;
      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr;
      justify-content: space-between;
      padding-top: 4rem;
    }
  }
  .Footer_footer__OWMwM .Footer_footer__button-group__NTE5O {
    margin-bottom: 2rem;
  }
  .Footer_footer__OWMwM .Footer_footer__culture__NDU5O {
    width: 100%;
  }
  @media (min-width: 64.0625rem) {
    .Footer_footer__OWMwM .Footer_footer__culture-compress__OGI4Y {
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .Footer_footer__OWMwM .Footer_footer__user-links__ZGY1N {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
  @media (min-width: 64.0625rem) {
    .Footer_footer__OWMwM .Footer_footer__user-links__ZGY1N :first-child {
      padding-bottom: 0.5rem;
    }
    .Footer_footer__OWMwM .Footer_footer__user-links__ZGY1N :not(:first-child) {
      padding: 0.5rem 0;
    }
  }
  .Footer_footer__OWMwM .Footer_footer__link__YWIyM {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
  }
  @media (max-width: 64rem) {
    .Footer_footer__OWMwM .Footer_footer__link__YWIyM {
      padding: 0.5rem 0;
    }
    .Footer_footer__OWMwM .Footer_footer__seo-links__MmI0Z {
      margin-top: 1rem;
      padding-top: 1rem;
    }
  }
  @media (min-width: 64.0625rem) {
    .Footer_footer__OWMwM .Footer_footer__seo-links__MmI0Z {
      margin-top: -0.5rem;
    }
  }
  @media (min-width: 64.0625rem) {
    .MobileAppButton_mobile-app-button__MzA1N {
      display: none;
    }
  }
  .MobileAppButton_mobile-app-button__download__NGUzM {
    display: flex;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;
  }
  .MobileAppButton_mobile-app-button__icon__MDAwM {
    fill: #eff3f8;
    color: #eff3f8;
    margin-inline-end: 0.5rem;
  }
  .FooterGroup_footer-group__YjE0O {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .FooterGroup_footer-group__YjE0O button > div {
    margin: 0.5rem 0;
  }
  .FooterLinkList_footer-link-list__MjlhY {
    list-style-type: none;
    margin: 0 1rem;
    padding: 0;
  }
  .FooterLinkList_footer-link-list--hidden__ZWVlN {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    list-style-type: none;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .FooterLinkList_footer-link-list__link__NzZiM {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .FooterLink_footer-link__lists__NzcyZ {
    margin-left: 0.25rem;
  }
  .FooterLink_footer-link__link-expand__Yjk4M {
    display: flex;
    width: 100%;
  }
  .FooterLink_footer-link__link__OWFhY {
    word-wrap: break-word;
    color: #eff3f8;
    flex: 0 1 auto;
    margin: 0.5rem 0;
    width: -moz-fit-content;
    width: fit-content;
  }
  .FooterLink_footer-link__link__OWFhY:visited {
    color: #eff3f8;
  }
  .FooterLink_footer-link__text__ZTEyM {
    color: #eff3f8;
    margin: 0.5rem 0;
  }
  .FooterLinkToggle_footer-link-toggle__link__Y2YzM {
    background: none;
    border: none;
    cursor: pointer;
    flex: 0 0 auto;
    padding: 0;
  }
  .FooterLinkToggle_footer-link-toggle__link__Y2YzM :focus {
    outline: none;
  }
  .FooterLinkToggle_footer-link-toggle__icon__ZTFlY {
    fill: #eff3f8;
    color: #eff3f8;
    margin: 0 0 0 0.5rem;
  }
  .Copyright_copyright__NjNlZ .Copyright_copyright__container__ODRlO {
    margin-top: 1rem;
  }
  @media (max-width: 64rem) {
    .Copyright_copyright__NjNlZ .Copyright_copyright__container__ODRlO {
      padding: 1.5rem 0;
    }
  }
  @media (min-width: 64.0625rem) {
    .Copyright_copyright__NjNlZ .Copyright_copyright__container__ODRlO {
      margin: 0 auto;
      padding: 2.5rem 0 1.5rem;
      width: -moz-fit-content;
      width: fit-content;
    }
  }
  .Copyright_copyright__NjNlZ .Copyright_copyright__content__NDE1Z {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    width: 100%;
  }
  @media (min-width: 64.0625rem) {
    .Copyright_copyright__NjNlZ .Copyright_copyright__content__NDE1Z {
      grid-column: span 4;
      text-align: center;
    }
  }
`;
