const SearchBar = () => {
  return (
    <div className='SearchControls_container__MjY4N'>
      <div className='SearchControls_grid__ZmQzN'>
        <div className='SearchControls_origin__Yzg4Y'>
          <div>
            <div className='OriginDesktopSearchControlGroup_autoSuggestContainer__N2IwN'>
              <label
                id='originInput-label'
                className='AutoSuggest_label__ZDg1M OriginDesktopSearchControlGroup_desktopOriginLabel__ZDlhO'>
                <div className='AutoSuggest_inputTextWrapper__ODIzZ'>
                  <span className='BpkText_bpk-text__MjhhY BpkText_bpk-text--body-default__YzU1M OriginDesktopSearchControlGroup_inputLabel__NmNkZ'>
                    From
                  </span>
                  <div className='AutoSuggest_inputWrapper__OTRkM'>
                    <input
                      id='originInput-input'
                      className='AutoSuggest_input__ZTg5Y'
                      type='text'
                      autoCapitalize='on'
                      autoCorrect='off'
                      placeholder='Country, city or airport'
                      spellCheck='false'
                      maxLength={256}
                      name='originInput-search'
                      enterkeyhint='Search'
                      defaultValue=''
                    />
                  </div>
                </div>
              </label>
              <div className='AutoSuggest_suggestionsContainer__YWZlM AutoSuggest_desktopSuggestionsContainer__ODcxM'>
                <ul
                  id='originInput-menu'
                  className='AutoSuggest_suggestionsList__MmQ4N AutoSuggest_desktopSuggestionsList__ZmJkY'
                />
              </div>
            </div>
            <div className='bpk-aria-live BpkAriaLive_bpk-aria-live--invisible__NDdlZ'>
              {' '}
              selected
            </div>
          </div>
        </div>
        <div className='SearchControls_destinationContainer__NTg1Z'>
          <div className='SearchControls_destinationInput__MGI3N'>
            <div>
              <div className='DestinationSearchControlsGroup_autoSuggestContainer__Y2FmY'>
                <label
                  id='destinationInput-label'
                  htmlFor='destinationInput-input'
                  className='AutoSuggest_label__ZDg1M DestinationSearchControlsGroup_desktopDestinationLabel__MmM4M'>
                  <div className='AutoSuggest_inputTextWrapper__ODIzZ'>
                    <span className='BpkText_bpk-text__MjhhY BpkText_bpk-text--body-default__YzU1M DestinationSearchControlsGroup_inputLabel__ODVjY'>
                      To
                    </span>
                    <div className='AutoSuggest_inputWrapper__OTRkM'>
                      <input
                        id='destinationInput-input'
                        className='AutoSuggest_input__ZTg5Y'
                        type='text'
                        autoCapitalize='on'
                        placeholder='Country, city or airport'
                        maxLength={256}
                      />
                    </div>
                  </div>
                </label>
                <div className='AutoSuggest_suggestionsContainer__YWZlM AutoSuggest_desktopSuggestionsContainer__ODcxM'>
                  <ul
                    id='destinationInput-menu'
                    className='AutoSuggest_suggestionsList__MmQ4N AutoSuggest_desktopSuggestionsList__ZmJkY'
                  />
                </div>
              </div>
              <div className='bpk-aria-live BpkAriaLive_bpk-aria-live--invisible__NDdlZ'>
                {' '}
                selected
              </div>
            </div>
          </div>
        </div>
        <div className='DateSearchControlsGroup_desktopDatesContainer__YTIwM DateSearchControlsGroup_flexibleDateContainer__MTc0Y'>
          <div className='DateSearchControlsGroup_datesContainer__ZmEzY'>
            <div className='SearchControlButton_container__YmNmM SearchControlButton_datesSearchControlButton__MGVkY SearchControlButton_dates__ZWVkO'>
              <button className='SearchControlButton_searchControlBtn__YTQxZ SearchControlButton_datesSearchControlButton__MGVkY SearchControlButton_dates__ZWVkO'>
                <span className='BpkText_bpk-text__MjhhY BpkText_bpk-text--body-default__YzU1M SearchControlButton_label__YTVmM'>
                  Depart
                </span>
                <span className='BpkText_bpk-text__MjhhY BpkText_bpk-text--body-default__YzU1M SearchControlButton_value__NTAyZ'>
                  19/08/2024
                </span>
              </button>
            </div>
          </div>
          <div className='DateSearchControlsGroup_datesContainer__ZmEzY'>
            <div className='SearchControlButton_container__YmNmM SearchControlButton_datesSearchControlButton__MGVkY SearchControlButton_dates__ZWVkO'>
              <button
                type='button'
                className='SearchControlButton_searchControlBtn__YTQxZ SearchControlButton_datesSearchControlButton__MGVkY SearchControlButton_dates__ZWVkO'>
                <span className='BpkText_bpk-text__MjhhY BpkText_bpk-text--body-default__YzU1M SearchControlButton_label__YTVmM'>
                  Return
                </span>
                <span className='BpkText_bpk-text__MjhhY BpkText_bpk-text--body-default__YzU1M SearchControlButton_value__NTAyZ'>
                  26/08/2024
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className='CabinClassTravellerSearchControlGroup_travellerContainer__NzJlM'>
          <div className='SearchControlButton_container__YmNmM SearchControlButton_traveller__ODE5Y'>
            <button
              type='button'
              className='SearchControlButton_searchControlBtn__YTQxZ SearchControlButton_traveller__ODE5Y'>
              <span className='BpkText_bpk-text__MjhhY BpkText_bpk-text--body-default__YzU1M SearchControlButton_label__YTVmM'>
                Travellers and cabin class
              </span>
              <span className='BpkText_bpk-text__MjhhY BpkText_bpk-text--body-default__YzU1M SearchControlButton_value__NTAyZ'>
                1 Adult, Economy
              </span>
            </button>
          </div>
        </div>
        <div className='SearchControls_directFlights__ZmMwY'>
          <label className='BpkCheckbox_bpk-checkbox__Mzg1Y BpkCheckbox_bpk-checkbox--white__YTliZ'>
            <input
              type='checkbox'
              className='BpkCheckbox_bpk-checkbox__input__ZDJiM BpkCheckbox_bpk-checkbox__input-white__NTQyO'
            />
            <span className='BpkCheckbox_bpk-checkbox__label__MjJhZ'>
              Direct flights
            </span>
          </label>
        </div>
        <button
          type='button'
          className='BpkButton_bpk-button__OTE4Z BpkButton_bpk-button--large__NTAyN BpkButton_bpk-button--featured__NTk3N SearchControls_desktopCTA__MjcwM'>
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
