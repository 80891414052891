import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import About from '../components/About';
import Contact from '../components/Contact';

const ContactPage = () => {
  return (
    <>
      <Hero black />
      <Contact />
      <Footer />
    </>
  );
};

export default ContactPage;
