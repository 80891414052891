import styled from 'styled-components';

export const Hero = styled.div`
  --background-image-mobile: url(https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true);
  --background-image-tablet: url(https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true);
  --background-image-desktop: url(https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true);
  margin-top: 2.5rem;
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/bpk-styles.ad96fe6e.chunk.css */

  a:active,
  a:hover {
    outline-width: 0;
  }
  img {
    border-style: none;
  }
  @media (min-width: 64.0625rem) {
    .GraphicPromoFallbackCard_graphicPromo__YTYxY {
      background-image: var(--background-image-desktop);
    }
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  .BpkText_bpk-text__MjhhY {
    margin: 0;
  }
  .BpkText_bpk-text--body-default__YzU1M {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .bpk-no-touch-support
    .BpkCard_bpk-card__Yjk0N:hover:not(:active):not(:disabled):after {
    opacity: 1;
  }
  .BpkCard_bpk-card__Yjk0N:after {
    border-radius: 0.75rem;
    bottom: 0;
    box-shadow: 0 4px 14px 0 #25201f40;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s ease-in-out;
  }
  @media screen\0 {
    .BpkCard_bpk-card__Yjk0N:after {
      z-index: -1;
    }
  }
  .BpkCard_bpk-card__Yjk0N:active:after {
    box-shadow: none;
  }
  .BpkCard_bpk-card--padded__ZWYxN {
    padding: 1rem;
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/Homepage.218979eb.chunk.css */
  .Homepage_navAndAdsWrapper__YjhlN {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
  }
  @media (min-width: 64.0625rem) {
    .Homepage_navAndAdsWrapper__YjhlN {
      padding: 0 3rem;
    }
  }
  @media (max-width: 64rem) {
    .Homepage_navAndAdsWrapper__YjhlN {
      padding: 0 2rem;
    }
  }
  @media (max-width: 48rem) {
    .Homepage_navAndAdsWrapper__YjhlN {
      padding: 0 1.5rem;
    }
  }
  @media (max-width: 32rem) {
    .Homepage_navAndAdsWrapper__YjhlN {
      padding: 0 1.5rem;
    }
  }
  @media (max-width: 22.4375rem) {
    .Homepage_navAndAdsWrapper__YjhlN {
      padding: 0 1rem;
    }
  }
  @media (max-width: 48rem) {
    .Homepage_navAndAdsWrapper__YjhlN {
      row-gap: 1.5rem;
    }
  }
  .GlobalNavigation_container__MWFiN {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .GlobalNavigation_cardsContainer__ZjJhN {
    column-gap: 0.5rem;
    display: flex;
    margin: 0 auto;
    white-space: normal;
  }
  @media (min-width: 64.0625rem) {
    .GlobalNavigation_cardsContainer__ZjJhN {
      column-gap: 1rem;
      max-width: 79.5rem;
    }
  }
  .GlobalNavigationCard_container__MTZkO {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0.25rem;
  }
  .GlobalNavigationCard_card__NjMwN {
    background-color: #05203c;
    box-shadow: 0 1px 3px 0 #25201f4d;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: fit-content !important;
    justify-content: space-between;
    min-width: 8.75rem;
    border-radius: 0.75rem;
  }
  @media (min-width: 64.0625rem) {
    .GlobalNavigationCard_card__NjMwN {
      align-items: center;
      flex-direction: row;
      height: 4.5rem;
      justify-content: flex-start;
      min-width: 13.375rem;
    }
  }
  .GlobalNavigationCard_card__NjMwN:active,
  .GlobalNavigationCard_card__NjMwN:hover {
    background-color: #054184;
    transition-duration: 0.2s;
    transition-property: background-color;
    transition-timing-function: ease;
  }
  .GlobalNavigationCard_logoImage__YWY4Y {
    aspect-ratio: 1/1;
    max-width: 2.5rem;
  }
  @media (max-width: 64rem) {
    .GlobalNavigationCard_logoImage__YWY4Y {
      margin-top: -0.5rem;
      margin-inline-start: -0.5rem;
    }
  }
  @media (min-width: 64.0625rem) {
    .GlobalNavigationCard_logoImage__YWY4Y {
      margin-inline-end: 0.5rem;
      min-width: 2.5rem;
    }
  }
  .GlobalNavigationCard_container__MTZkO:first-child {
    padding-inline-start: 1.5rem;
  }
  @media (min-width: 64.0625rem) {
    .GlobalNavigationCard_container__MTZkO:first-child {
      margin-inline-start: 1.5rem;
      padding-inline-start: 0;
    }
  }
  .GlobalNavigationCard_container__MTZkO:last-child {
    padding-inline-end: 1.5rem;
  }
  @media (min-width: 64.0625rem) {
    .GlobalNavigationCard_container__MTZkO:last-child {
      margin-inline-end: 1.5rem;
      padding-inline-end: 0;
    }
  }
  .GlobalNavigationCard_truncate__YmRmY {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    bottom: 1rem;
    display: -webkit-box;
    left: 1rem;
    overflow: hidden;
    right: 1rem;
    text-overflow: ellipsis;
  }
  .GlobalNavigationCard_responsiveTextStyle__NWU2Y {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    overflow: inherit;
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/bpk-styles.ad96fe6e.chunk.css */
  button {
    font: inherit;
    margin: 0;
  }
  button {
    overflow: visible;
  }
  button {
    text-transform: none;
  }
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  .BpkButton_bpk-button__OTE4Z {
    background-color: #05203c;
    background-color: var(--bpk-button-primary-background-color, #05203c);
    border: 0;
    border-radius: 0.5rem;
    color: #fff;
    color: var(--bpk-button-primary-text-color, #fff);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 0;
    min-height: 2.25rem;
    padding: 0.375rem 1rem;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
  }
  .bpk-no-touch-support
    .BpkButton_bpk-button__OTE4Z:hover:not(:active):not(:disabled) {
    background-color: #154679;
    background-color: var(--bpk-button-primary-hover-background-color, #154679);
    color: #fff;
    color: var(--bpk-button-primary-hover-text-color, #fff);
  }
  .BpkButton_bpk-button__OTE4Z:active {
    background-color: #154679;
    background-color: var(
      --bpk-button-primary-active-background-color,
      #154679
    );
    color: #fff;
    color: var(--bpk-button-primary-active-text-color, #fff);
  }
  .BpkButton_bpk-button__OTE4Z:disabled {
    background-color: #e0e4e9;
    color: #0003;
    cursor: not-allowed;
  }
  .BpkButton_bpk-button--primary-on-dark__NGU2Z {
    background-color: #fff;
    background-color: var(--bpk-button-primary-on-dark-background-color, #fff);
    color: #161616;
    color: var(--bpk-button-primary-on-dark-text-color, #161616);
  }
  .bpk-no-touch-support
    .BpkButton_bpk-button--primary-on-dark__NGU2Z:hover:not(:active):not(
      :disabled
    ) {
    background-color: #c1c7cf;
    background-color: var(
      --bpk-button-primary-on-dark-hover-background-color,
      #c1c7cf
    );
    color: #161616;
    color: var(--bpk-button-primary-on-dark-hover-text-color, #161616);
  }
  .BpkButton_bpk-button--primary-on-dark__NGU2Z:active {
    background-color: #c1c7cf;
    background-color: var(
      --bpk-button-primary-on-dark-active-background-color,
      #c1c7cf
    );
    color: #161616;
    color: var(--bpk-button-primary-on-dark-active-text-color, #161616);
  }
  .BpkButton_bpk-button--primary-on-dark__NGU2Z:disabled {
    background-color: #e0e4e9;
    color: #0003;
  }
  .BpkGraphicPromo_bpk-card__OGQwO {
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px 0 #25201f4d;
    color: #161616;
    cursor: pointer;
    display: block;
    position: relative;
    text-decoration: none;
  }
  .bpk-no-touch-support
    .BpkGraphicPromo_bpk-card__OGQwO:hover:not(:active):not(:disabled):after {
    opacity: 1;
  }
  .BpkGraphicPromo_bpk-card__OGQwO:after {
    border-radius: 0.75rem;
    bottom: 0;
    box-shadow: 0 4px 14px 0 #25201f40;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s ease-in-out;
  }
  @media screen\0 {
    .BpkGraphicPromo_bpk-card__OGQwO:after {
      z-index: -1;
    }
  }
  .BpkGraphicPromo_bpk-card__OGQwO:active:after {
    box-shadow: none;
  }
  .BpkGraphicPromo_bpk-graphic-promo__ZWFjZ {
    background-color: #05203c;
    background-position: 50%;
    background-size: cover;
    box-shadow: none;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 76.5rem;
  }
  .BpkGraphicPromo_bpk-graphic-promo__ZWFjZ {
    background-color: #05203c;
    background-position: 50%;
    background-size: cover;
    box-shadow: none;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 76.5rem;
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__ZWFjZ {
      box-sizing: border-box;
      padding-top: 133%;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__ZWFjZ:after {
    display: none;
  }
  .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .BpkGraphicPromo_bpk-graphic-promo__container--start__MTI4M {
    align-items: start;
  }
  @media (min-width: 64.0625rem) {
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
      height: 28.75rem;
      margin-left: 4rem;
      margin-right: 2.5rem;
    }
  }
  @media (max-width: 64rem) {
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
      height: 22.5rem;
      margin-left: 2.5rem;
      margin-right: 2rem;
    }
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
      align-items: start;
      height: 100%;
      justify-content: space-between;
      left: 0;
      margin-left: 0;
      margin-right: 0;
      padding: 2rem;
      position: absolute;
      right: 0;
      text-align: start;
      top: 0;
    }
    .BpkGraphicPromo_bpk-graphic-promo__container--inverted__YzRkM {
      flex-direction: column-reverse;
    }
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN > * {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (max-width: 22.4375rem) {
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
      padding: 1.5rem;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__tagline__MjkwO {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }
  @media (max-width: 64rem) {
    .BpkGraphicPromo_bpk-graphic-promo__tagline__MjkwO {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
    }
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__tagline__MjkwO {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
    }
  }
  @media (max-width: 22.4375rem) {
    .BpkGraphicPromo_bpk-graphic-promo__tagline__MjkwO {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
    margin: 0;
    color: #fff !important;
  }
  @media (min-width: 64.0625rem) {
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
      font-size: 3rem;
      font-weight: 900;
      letter-spacing: -0.04em;
      line-height: 3rem;
    }
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  @media (max-width: 64rem) {
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3rem;
    }
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
      margin-bottom: 0.5rem;
    }
  }
  @media (max-width: 32rem) {
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.5rem;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__promo-content__M2QyM,
  .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
    display: flex;
    flex-direction: column;
  }
  .BpkGraphicPromo_bpk-graphic-promo__promo-content--start__OGU2O,
  .BpkGraphicPromo_bpk-graphic-promo__sponsor-content--start__MjFmN {
    align-items: start;
    text-align: start;
  }
  @media (min-width: 64.0625rem) {
    .BpkGraphicPromo_bpk-graphic-promo__promo-content__M2QyM,
    .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
      width: 22.5rem;
    }
  }
  @media (max-width: 64rem) {
    .BpkGraphicPromo_bpk-graphic-promo__promo-content__M2QyM,
    .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
      width: 20rem;
    }
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__promo-content__M2QyM,
    .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
      align-items: start;
      text-align: start;
      width: auto;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
      margin-bottom: 0;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__cta__ZTRlM {
    margin-top: 2rem;
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__cta__ZTRlM {
      display: none;
    }
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/Homepage.218979eb.chunk.css */
  .GraphicPromoFallbackCard_graphicPromo__YTYxY {
    background-color: #fff;
    max-width: 76.5rem;
  }
  @media (min-width: 64.0625rem) {
    .GraphicPromoFallbackCard_graphicPromo__YTYxY {
      background-image: 'https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true';
    }
  }
  @media (max-width: 64rem) {
    .GraphicPromoFallbackCard_graphicPromo__YTYxY {
      background-image: 'https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true';
    }
  }
  @media (max-width: 48rem) {
    .GraphicPromoFallbackCard_graphicPromo__YTYxY {
      background-image: 'https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true';
    }
  }
`;

export const ImageBanner = styled.div`
  --background-image-mobile: url(https://content.skyscnr.com/m/5b8b37756ec818f9/original/skyscanner-x-tui-madeira.jpg?imbypass=true);
  --background-image-tablet: url(https://content.skyscnr.com/m/5b8b37756ec818f9/original/skyscanner-x-tui-madeira.jpg?imbypass=true);
  --background-image-desktop: url(https://content.skyscnr.com/m/5b8b37756ec818f9/original/skyscanner-x-tui-madeira.jpg?imbypass=true);
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/bpk-styles.ad96fe6e.chunk.css */
  button {
    font: inherit;
    margin: 0;
  }
  button {
    overflow: visible;
  }
  button {
    text-transform: none;
  }
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  .BpkButton_bpk-button__OTE4Z {
    background-color: #05203c;
    background-color: var(--bpk-button-primary-background-color, #05203c);
    border: 0;
    border-radius: 0.5rem;
    color: #fff;
    color: var(--bpk-button-primary-text-color, #fff);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 0;
    min-height: fit-content;
    padding: 0.375rem 1rem;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
    margin-top: 2rem;
  }
  .bpk-no-touch-support
    .BpkButton_bpk-button__OTE4Z:hover:not(:active):not(:disabled) {
    background-color: #154679;
    background-color: var(--bpk-button-primary-hover-background-color, #154679);
    color: #fff;
    color: var(--bpk-button-primary-hover-text-color, #fff);
  }
  .BpkButton_bpk-button__OTE4Z:active {
    background-color: #154679;
    background-color: var(
      --bpk-button-primary-active-background-color,
      #154679
    );
    color: #fff;
    color: var(--bpk-button-primary-active-text-color, #fff);
  }
  .BpkButton_bpk-button__OTE4Z:disabled {
    background-color: #e0e4e9;
    color: #0003;
    cursor: not-allowed;
  }
  .BpkButton_bpk-button--primary-on-dark__NGU2Z {
    background-color: #fff;
    background-color: var(--bpk-button-primary-on-dark-background-color, #fff);
    color: #161616;
    color: var(--bpk-button-primary-on-dark-text-color, #161616);
  }
  .bpk-no-touch-support
    .BpkButton_bpk-button--primary-on-dark__NGU2Z:hover:not(:active):not(
      :disabled
    ) {
    background-color: #c1c7cf;
    background-color: var(
      --bpk-button-primary-on-dark-hover-background-color,
      #c1c7cf
    );
    color: #161616;
    color: var(--bpk-button-primary-on-dark-hover-text-color, #161616);
  }
  .BpkButton_bpk-button--primary-on-dark__NGU2Z:active {
    background-color: #c1c7cf;
    background-color: var(
      --bpk-button-primary-on-dark-active-background-color,
      #c1c7cf
    );
    color: #161616;
    color: var(--bpk-button-primary-on-dark-active-text-color, #161616);
  }
  .BpkButton_bpk-button--primary-on-dark__NGU2Z:disabled {
    background-color: #e0e4e9;
    color: #0003;
  }
  .BpkGraphicPromo_bpk-card__OGQwO {
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px 0 #25201f4d;
    color: #161616;
    cursor: pointer;
    display: block;
    position: relative;
    text-decoration: none;
  }
  .bpk-no-touch-support
    .BpkGraphicPromo_bpk-card__OGQwO:hover:not(:active):not(:disabled):after {
    opacity: 1;
  }
  .BpkGraphicPromo_bpk-card__OGQwO:after {
    border-radius: 0.75rem;
    bottom: 0;
    box-shadow: 0 4px 14px 0 #25201f40;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s ease-in-out;
  }
  @media screen\0 {
    .BpkGraphicPromo_bpk-card__OGQwO:after {
      z-index: -1;
    }
  }
  .BpkGraphicPromo_bpk-card__OGQwO:active:after {
    box-shadow: none;
  }
  .BpkGraphicPromo_bpk-graphic-promo__ZWFjZ {
    background-color: #05203c;
    background-position: 50%;
    background-size: cover;
    box-shadow: none;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 76.5rem;
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__ZWFjZ {
      box-sizing: border-box;
      padding-top: 133%;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__ZWFjZ:after {
    display: none;
  }
  .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .BpkGraphicPromo_bpk-graphic-promo__container--start__MTI4M {
    align-items: start;
  }
  @media (min-width: 64.0625rem) {
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
      height: 28.75rem;
      margin-left: 4rem;
      margin-right: 2.5rem;
    }
  }
  @media (max-width: 64rem) {
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
      height: 22.5rem;
      margin-left: 2.5rem;
      margin-right: 2rem;
    }
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
      align-items: start;
      height: 100%;
      justify-content: space-between;
      left: 0;
      margin-left: 0;
      margin-right: 0;
      padding: 2rem;
      position: absolute;
      right: 0;
      text-align: start;
      top: 0;
    }
    .BpkGraphicPromo_bpk-graphic-promo__container--inverted__YzRkM {
      flex-direction: column-reverse;
    }
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN > * {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (max-width: 22.4375rem) {
    .BpkGraphicPromo_bpk-graphic-promo__container__M2NjN {
      padding: 1.5rem;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__tagline__MjkwO {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
    color: #fff;
  }
  @media (max-width: 64rem) {
    .BpkGraphicPromo_bpk-graphic-promo__tagline__MjkwO {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
    }
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__tagline__MjkwO {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
    }
  }
  @media (max-width: 22.4375rem) {
    .BpkGraphicPromo_bpk-graphic-promo__tagline__MjkwO {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
    margin: 0;
    color: #fff;
  }
  @media (min-width: 64.0625rem) {
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
      font-size: 3rem;
      font-weight: 900;
      letter-spacing: -0.04em;
      line-height: 3rem;
    }
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  @media (max-width: 64rem) {
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3rem;
    }
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
      margin-bottom: 0.5rem;
    }
  }
  @media (max-width: 32rem) {
    .BpkGraphicPromo_bpk-graphic-promo__headline__OTE3M {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.5rem;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__promo-content__M2QyM,
  .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
    display: flex;
    flex-direction: column;
  }
  .BpkGraphicPromo_bpk-graphic-promo__promo-content--start__OGU2O,
  .BpkGraphicPromo_bpk-graphic-promo__sponsor-content--start__MjFmN {
    align-items: start;
    text-align: start;
  }
  @media (min-width: 64.0625rem) {
    .BpkGraphicPromo_bpk-graphic-promo__promo-content__M2QyM,
    .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
      width: 22.5rem;
    }
  }
  @media (max-width: 64rem) {
    .BpkGraphicPromo_bpk-graphic-promo__promo-content__M2QyM,
    .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
      width: 20rem;
    }
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__promo-content__M2QyM,
    .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
      align-items: start;
      text-align: start;
      width: auto;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__sponsor-content__ODU0N {
      margin-bottom: 0;
    }
  }
  .BpkGraphicPromo_bpk-graphic-promo__cta__ZTRlM {
    margin-top: 2rem;
  }
  @media (max-width: 48rem) {
    .BpkGraphicPromo_bpk-graphic-promo__cta__ZTRlM {
      display: none;
    }
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/Homepage.218979eb.chunk.css */
  .GraphicPromoFallbackCard_graphicPromo__YTYxY {
    background-color: #fff;
    max-width: 76.5rem;
  }
  @media (min-width: 64.0625rem) {
    .GraphicPromoFallbackCard_graphicPromo__YTYxY {
      background-image: 'https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true';
    }
  }
  @media (max-width: 64rem) {
    .GraphicPromoFallbackCard_graphicPromo__YTYxY {
      background-image: 'https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true';
    }
  }
  @media (max-width: 48rem) {
    .GraphicPromoFallbackCard_graphicPromo__YTYxY {
      background-image: 'https://content.skyscnr.com/m/6ba7ed2583688fde/original/skyscanner-x-tui-madeira.jpg?imbypass=true';
    }
  }
`;

export const Seo = styled.div`
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/bpk-styles.ad96fe6e.chunk.css */
  a {
    -webkit-text-decoration-skip: objects;
    background-color: initial;
  }
  a:active,
  a:hover {
    outline-width: 0;
  }
  b {
    font-weight: inherit;
    font-weight: bolder;
  }
  img {
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  button {
    font: inherit;
    margin: 0;
  }
  button {
    overflow: visible;
  }
  button {
    text-transform: none;
  }
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  .BpkText_bpk-text__MjhhY {
    margin: 0;
  }
  .BpkText_bpk-text--footnote__OWIwY {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .BpkText_bpk-text--label-1__OGE0O {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
  .BpkText_bpk-text--body-default__YzU1M {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .BpkText_bpk-text--heading-3__MDU2N {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
  .BpkText_bpk-text--heading-4__Y2FlY {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
  .BpkText_bpk-text--heading-5__MTRjZ {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
  }
  .BpkLink_bpk-link__MmQwY {
    -webkit-appearance: none;
    appearance: none;
    background-color: #0000;
    border: 0;
    color: #0062e3;
    color: var(--bpk-link-color, #0062e3);
    cursor: pointer;
    padding: 0;
    text-decoration: none;
  }
  .bpk-no-touch-support
    .BpkLink_bpk-link__MmQwY:hover:not(:active):not(:disabled) {
    color: #0062e3;
    color: var(--bpk-link-hover-color, #0062e3);
    text-decoration: underline;
  }
  .BpkLink_bpk-link__MmQwY:visited {
    color: #0062e3;
    color: var(--bpk-link-visited-color, #0062e3);
  }
  .BpkLink_bpk-link__MmQwY:active {
    color: #0062e3;
    color: var(--bpk-link-active-color, #0062e3);
    text-decoration: underline;
  }
  .BpkAccordion_bpk-accordion__M2FiY {
    margin: 0;
  }
  .BpkImage_bpk-image__Y2I5Y {
    background-color: #e0e4e9;
    display: block;
    max-width: 100%;
    opacity: 1;
    overflow: hidden;
    position: relative;
    transition: background-color 0.4s ease-in-out;
    transition-delay: 0.4s;
  }
  .BpkImage_bpk-image__img__MTAwN {
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.4s ease-in-out;
    width: 100%;
  }
  .BpkImage_bpk-image--no-background__NjUwZ {
    background-color: #0000;
  }
  .BpkAccordionItem_bpk-accordion__item--with-divider__NjU4Z {
    box-shadow: inset 0 -1px 0 0 #c1c7cf;
  }
  .BpkAccordionItem_bpk-accordion__title__ZTg3Z {
    height: auto;
  }
  .BpkAccordionItem_bpk-accordion__toggle-button__MGVlM {
    -webkit-appearance: none;
    appearance: none;
    background-color: #0000;
    border: 0;
    color: #161616;
    cursor: pointer;
    padding: 0;
    text-align: left;
    width: 100%;
  }
  .BpkAccordionItem_bpk-accordion__flex-container__MzM5O {
    display: inline-flex;
    flex-direction: row;
    margin: 1rem 0;
    width: 100%;
  }
  .BpkAccordionItem_bpk-accordion__title-text__OTZkY {
    flex-grow: 1;
  }
  .BpkAccordionItem_bpk-accordion__icon-wrapper__MGUzM {
    display: inline-block;
  }
  .BpkAccordionItem_bpk-accordion__item-expand-icon__NWYxN {
    fill: #161616;
  }
  .BpkAccordionItem_bpk-accordion__content-container__YmFhO {
    margin: 0;
  }
  .BpkAccordionItem_bpk-accordion__content-inner-container--with-divider__ZjJiZ {
    padding-bottom: 1rem;
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/banana/static/css/SeoComponents.122c926c.chunk.css */
  .SeoComponents_seoComponents__MzE1Z {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 76.5rem;
    row-gap: 2.5rem;
    width: 100%;
  }
  @media (max-width: 48rem) {
    .SeoComponents_seoComponents__MzE1Z {
      row-gap: 1.5rem;
    }
  }
  .FAQs_faqs__ZWJiZ {
    margin-bottom: 2rem;
  }
  .FAQs_faqs__ZWJiZ .FAQs_faqsHeader__MWRmM {
    padding-bottom: 0.5rem;
  }
  .FAQs_faqs__ZWJiZ .FAQs_column__NTIxY {
    display: flex;
    gap: 1rem;
  }
  @media (max-width: 64rem) {
    .FAQs_faqs__ZWJiZ .FAQs_column__NTIxY {
      flex-direction: column;
      gap: 0;
    }
  }
  .FAQs_faqs__ZWJiZ .FAQs_block__MzBkZ {
    width: 100%;
  }
  .FAQs_faqs__ZWJiZ .FAQs_subsection__NjM1Y {
    margin-top: 0.5rem;
  }
  .FAQs_faqs__ZWJiZ .FAQs_header__YWQ3N {
    margin-inline-end: 0.25rem;
  }
  .InternationalSites_sitesContainer__OTMyO {
    margin-top: 1.5rem;
  }
  @media (min-width: 32.0625rem) {
    .InternationalSites_sitesContainer__OTMyO {
      display: grid;
      grid-template-columns: auto auto;
    }
  }
  @media (min-width: 64.0625rem) {
    .InternationalSites_sitesContainer__OTMyO {
      grid-template-columns: auto auto auto;
      margin-right: 0;
    }
  }
  .InternationalSiteCard_siteContainer__ZjA4Z {
    display: flex;
    margin-bottom: 1rem;
  }
  .InternationalSiteCard_image__ODU1N {
    width: 1.5rem;
  }
  .InternationalSiteCard_image__ODU1N img {
    border-radius: 0.75rem;
    height: 1.5rem;
  }
  .InternationalSiteCard_siteLink__NmU4M {
    display: flex;
  }
  .InternationalSiteCard_text__N2VlZ {
    margin: 0 1rem;
  }
`;

export const Confidence = styled.div`
  margin: 0 auto;
  max-width: 76.5rem;
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/bpk-styles.72c5268d.css */
  svg:not(:root) {
    overflow: hidden;
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  .BpkText_bpk-text__ZjI3M {
    margin: 0;
  }
  .BpkText_bpk-text--label-1__MWI4N {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/components-FeatureContainer.af90e648.chunk.css */
  .FeatureContainer_FeatureContainer__ZGZlO
    .FeatureContainer_FeatureItem__MmFiN:not(:last-child) {
    margin-bottom: 4rem;
  }
  @media (min-width: 32.0625rem) {
    .FeatureContainer_FeatureContainer__ZGZlO
      .FeatureContainer_FeatureItem__MmFiN:not(:last-child) {
      margin-bottom: 6rem;
    }
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/components-ConfidenceMessaging.4dfd6901.chunk.css */
  .ConfidenceMessaging_ConfidenceMessaging__MGQ5Y {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
  }
  .ConfidenceMessaging_ConfidenceMessaging__MGQ5Y
    .ConfidenceMessaging_item__OWJmM {
    align-items: flex-start;
    color: #161616;
    display: flex;
    flex: 0 1;
    flex-basis: auto;
    gap: 0.75rem;
    max-width: 33.3%;
    padding-right: 1.5rem;
  }
  .ConfidenceMessaging_ConfidenceMessaging__MGQ5Y
    .ConfidenceMessaging_icon__NmNlZ {
    display: flex;
    justify-content: flex-start;
  }
  .ConfidenceMessaging_ConfidenceMessaging__MGQ5Y
    .ConfidenceMessaging_description__YTY4N {
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  @media (max-width: 64rem) {
    .ConfidenceMessaging_ConfidenceMessaging__MGQ5Y
      .ConfidenceMessaging_item__OWJmM {
      flex-flow: column nowrap;
      gap: 0.75rem;
      padding-right: 1.5rem;
    }
  }
  @media (max-width: 32rem) {
    .ConfidenceMessaging_ConfidenceMessaging__MGQ5Y {
      flex-flow: column nowrap;
      gap: 1.5rem;
      padding: 0;
      position: relative;
    }
    .ConfidenceMessaging_ConfidenceMessaging__MGQ5Y
      .ConfidenceMessaging_item__OWJmM {
      flex: 1 0;
      flex-flow: row nowrap !important;
      gap: 0.75rem !important;
      max-width: 100%;
      padding-right: 0;
    }
  }
`;

export const ImageBlocks = styled.div`
  margin: 0 auto;
  max-width: 76.5rem;
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/bpk-styles.72c5268d.css */
  article {
    display: block;
  }
  img {
    border-style: none;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  .BpkText_bpk-text__ZjI3M {
    margin: 0;
  }
  .BpkText_bpk-text--body-default__ODg2M {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .BpkText_bpk-text--heading-2__MTg3O {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
  .BpkText_bpk-text--heading-3__YThkY {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
  .BpkImage_bpk-image__OTk5Y {
    background-color: #e0e4e9;
    display: block;
    max-width: 100%;
    opacity: 1;
    overflow: hidden;
    position: relative;
    transition: background-color 0.4s ease-in-out;
    transition-delay: 0.4s;
  }
  .BpkImage_bpk-image__img__MzNiM {
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.4s ease-in-out;
    width: 100%;
  }
  .BpkImage_bpk-image--no-background__NWQ5N {
    background-color: #0000;
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/components-FeatureContainer.af90e648.chunk.css */
  .FeatureContainer_FeatureContainer__ZGZlO
    .FeatureContainer_FeatureItem__MmFiN:not(:last-child) {
    margin-bottom: 4rem;
  }
  @media (min-width: 32.0625rem) {
    .FeatureContainer_FeatureContainer__ZGZlO
      .FeatureContainer_FeatureItem__MmFiN:not(:last-child) {
      margin-bottom: 6rem;
    }
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/components-IndexBlockPanel-IndexBlockPanel.8fc6cd13.chunk.css */
  .IndexBlockPanel_IndexBlockPanel__N2Y3M {
    background-color: #eff3f8;
    border-radius: 0.75rem;
  }
  .IndexBlockPanel_IndexBlockPanel__container__OWEyM {
    padding: 2.5rem 1.5rem;
    text-align: center;
    width: 100%;
  }
  .IndexBlockPanel_IndexBlockPanel__desc__ZDUwM {
    margin-top: 0.25rem;
  }
  .IndexBlockPanel_IndexBlockPanel__itemList__NmMzM {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    margin-top: 2rem;
  }
  @media (max-width: 48rem) {
    .IndexBlockPanel_IndexBlockPanel__itemList__NmMzM {
      flex-flow: column;
      margin-top: 1.5rem;
    }
  }
  .IndexBlockPanel_IndexBlockPanel__item__ZTBmZ {
    flex: 1 1;
    text-align: center;
  }
  .IndexBlockPanel_IndexBlockPanel__imgBox__ZTYwM {
    height: 13.625rem;
    margin: auto;
    width: 13.625rem;
  }
  .IndexBlockPanel_IndexBlockPanel__imgBox__ZTYwM img {
    height: 11.625rem;
  }
  .IndexBlockPanel_IndexBlockPanel__itemHead__MjJiY {
    margin-top: 1.5rem;
  }
  @media (max-width: 48rem) {
    .IndexBlockPanel_IndexBlockPanel__itemHead__MjJiY {
      margin-top: 1rem;
    }
  }
  .IndexBlockPanel_IndexBlockPanel__itemBody__MjI5M {
    color: #161616;
    margin-top: 0.25rem;
  }
`;

export const BottomInfo = styled.div`
  margin: 0 auto;
  max-width: 76.5rem;
  width: 100%;
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/bpk-styles.72c5268d.css */
  article {
    display: block;
  }
  img {
    border-style: none;
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  .BpkText_bpk-text__ZjI3M {
    margin: 0;
  }
  .BpkText_bpk-text--label-1__MWI4N {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
  .BpkText_bpk-text--body-default__ODg2M {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .BpkText_bpk-text--heading-3__YThkY {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/components-FeatureContainer.af90e648.chunk.css */
  .FeatureContainer_FeatureContainer__ZGZlO
    .FeatureContainer_FeatureItem__MmFiN:not(:last-child) {
    margin-bottom: 4rem;
  }
  @media (min-width: 32.0625rem) {
    .FeatureContainer_FeatureContainer__ZGZlO
      .FeatureContainer_FeatureItem__MmFiN:not(:last-child) {
      margin-bottom: 6rem;
    }
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/components-IndexBlockPlanPeacePanel.dcb229a0.chunk.css */
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__container__N2Y5N {
    max-width: 1048px;
    padding-bottom: 0.75rem;
    width: 95%;
  }
  @media (max-width: 32rem) {
    .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__container__N2Y5N {
      box-shadow: none;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      width: 100%;
    }
  }
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__topText__NzFjO h2 {
    margin-bottom: 0.75rem;
  }
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__itemList__ZGVhY {
    display: flex;
    justify-content: space-around;
    line-height: 1.75rem;
  }
  @media (max-width: 32rem) {
    .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__itemList__ZGVhY {
      display: block;
    }
  }
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__item__NTgwY {
    margin-top: 3.75rem;
    padding: 0 1.125rem 0 2.625rem;
    position: relative;
    width: 50%;
  }
  @media (max-width: 32rem) {
    .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__item__NTgwY {
      width: 100%;
    }
  }
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__imgBox__NWJhZ img {
    height: 1.25rem;
    left: 0;
    position: absolute;
    top: 0.2rem;
    width: 1.375rem;
  }
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__itemHead__ZjVhM {
    margin-bottom: 0.375rem;
  }
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__itemHead__ZjVhM p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__itemBody__OGUyM {
    color: #626971;
  }
  .IndexBlockPlanPeacePanel_IndexBlockPlanPeacePanel__itemBody__OGUyM p {
    line-height: 1.75rem;
  }
`;

export const Email = styled.div`
  margin: 0 auto;
  max-width: 76.5rem;
  width: 100%;
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/bpk-styles.72c5268d.css */
  footer {
    display: block;
  }
  a {
    -webkit-text-decoration-skip: objects;
    background-color: initial;
  }
  a:active,
  a:hover {
    outline-width: 0;
  }
  button,
  input {
    font: inherit;
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button {
    text-transform: none;
  }
  [type='submit'],
  button {
    -webkit-appearance: button;
  }
  *,
  :after,
  :before {
    box-sizing: inherit;
  }
  :focus-visible {
    outline: 0.125rem solid #0062e3;
    outline-offset: 0.125rem;
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/hamilton/falcon/static/css/components-FeatureContainer.af90e648.chunk.css */
  .FeatureContainer_FeatureContainer__ZGZlO
    .FeatureContainer_FeatureItem__MmFiN:last-child {
    margin-bottom: 1.5rem;
  }
  @media (min-width: 32.0625rem) {
    .FeatureContainer_FeatureContainer__ZGZlO
      .FeatureContainer_FeatureItem__MmFiN:last-child {
      margin-bottom: 2.5rem;
    }
  }
  /*! CSS Used from: https://js.skyscnr.com/sttc/oc-registry/components/email-capture/2.14.2/build//static/css/main.ac519cbe.css */
  .BpkButtonBase_bpk-button__ZTdiZ {
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 0.375rem;
    background-image: linear-gradient(-180deg, #00a698, #00a698);
    background-image: linear-gradient(
      -180deg,
      var(--bpk-button-primary-gradient-start-color, #00a698),
      var(--bpk-button-primary-gradient-end-color, #00a698)
    );
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
    color: var(--bpk-button-primary-text-color, #fff);
    background-color: #00a698;
    background-color: var(--bpk-button-primary-background-color, #00a698);
    font-size: 1.1875rem;
    font-size: var(--bpk-button-font-size, 1.1875rem);
    box-sizing: border-box;
  }
  .bpk-no-touch-support
    .BpkButtonBase_bpk-button__ZTdiZ:hover:not(:active):not(:disabled) {
    background-image: none;
    box-shadow: none;
    color: #fff;
    color: var(--bpk-button-primary-hover-text-color, #fff);
    background-color: #00887d;
    background-color: var(--bpk-button-primary-hover-background-color, #00887d);
  }
  .BpkButtonBase_bpk-button__ZTdiZ:active {
    background-image: none;
    box-shadow: none;
    color: #fff;
    color: var(--bpk-button-primary-active-text-color, #fff);
    background-color: #006a61;
    background-color: var(
      --bpk-button-primary-active-background-color,
      #006a61
    );
  }
  .BpkButtonBase_bpk-button__ZTdiZ:disabled {
    background-color: #dddde5;
    background-image: none;
    color: #b2b2bf;
    box-shadow: none;
    cursor: not-allowed;
  }
  .BpkInput_bpk-input__ZDRhN {
    display: inline-block;
    width: 100%;
    height: 2.25rem;
    padding: 0.5rem;
    border: 0.0625rem solid #b2b2bf;
    border-radius: 0.25rem;
    background: #fff;
    color: #111236;
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
  }
  .BpkInput_bpk-input__ZDRhN::placeholder {
    color: #68697f;
  }
  .BpkInput_bpk-input__ZDRhN:disabled {
    border-color: #f1f2f8;
    background: #fff;
    color: #b2b2bf;
    cursor: not-allowed;
  }
  .BpkInput_bpk-input__container__OGQyY {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .BpkInput_bpk-input--clearable__MTIyM {
    padding-right: 2rem;
  }
  .BpkInput_bpk-input--clearable__MTIyM:focus {
    background: #fff;
  }
  .InlineFormPresentational_InlineForm__OGQ4N {
    display: flex;
    margin-bottom: 1.5rem;
    padding: 0;
    flex-wrap: wrap;
  }
  @media (max-width: 50.25rem) {
    .InlineFormPresentational_InlineForm__OGQ4N {
      display: block;
    }
  }
  .InlineFormPresentational_InlineForm__email__ZmE2Y {
    width: 100%;
  }
  @media (min-width: 50.3125rem) {
    .InlineFormPresentational_InlineForm__email__ZmE2Y {
      width: auto;
      margin-right: 0.375rem;
      flex: 2 0 auto;
    }
  }
  .InlineFormPresentational_InlineForm__OGQ4N input {
    height: 3rem;
    font-size: 1rem;
  }
  .InlineFormPresentational_InlineForm__OGQ4N input::placeholder {
    font-style: normal;
  }
  .InlineFormPresentational_InlineForm__signUp__ZGFlZ {
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    vertical-align: baseline;
  }
  @media (max-width: 50.25rem) {
    .InlineFormPresentational_InlineForm__signUp__ZGFlZ {
      margin-top: 1.125rem;
    }
  }
  @media (min-width: 50.3125rem) {
    .InlineFormPresentational_InlineForm__signUp__ZGFlZ {
      width: auto;
      vertical-align: baseline;
    }
  }
  .BpkText_bpk-text__ZGJmY {
    margin: 0;
  }
  .BpkText_bpk-text--sm__NWNmY {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
  }
  .BpkText_bpk-text--base__MmRiZ {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
  .BpkText_bpk-text--xl__N2E4N {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
  }
  .BpkText_bpk-text--bold__MTUyO {
    font-weight: 700;
  }
  .BpkLink_bpk-link__YmUzM {
    padding: 0;
    border: 0;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    color: #0770e3;
    color: var(--bpk-link-color, #0770e3);
  }
  .bpk-no-touch-support
    .BpkLink_bpk-link__YmUzM:hover:not(:active):not(:disabled) {
    text-decoration: underline;
    color: #084eb2;
    color: var(--bpk-link-hover-color, #084eb2);
  }
  .BpkLink_bpk-link__YmUzM:visited {
    color: #0770e3;
    color: var(--bpk-link-visited-color, #0770e3);
  }
  .BpkLink_bpk-link__YmUzM:active {
    text-decoration: underline;
    color: #042759;
    color: var(--bpk-link-active-color, #042759);
  }
  .Copy_Copy__MDVlM {
    margin-bottom: 1.125rem;
  }
  .Copy_Copy__header__NzM0Y {
    margin-bottom: 0.75rem;
  }
  .Inline_Inline__ZmRmZ {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    border-radius: 0.375rem;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(37, 32, 31, 0.3);
    margin-bottom: 64px !important;
  }
  @media (max-width: 32.25rem) {
    .Inline_Inline__ZmRmZ {
      flex-direction: column-reverse;
    }
  }
  @media (min-width: 32.3125rem) {
    .Inline_Inline__ZmRmZ {
      position: relative;
      margin: auto;
    }
  }
  .Inline_Inline__form__OWY2M {
    max-width: 33rem;
    padding: 1.5rem;
  }
  @media (min-width: 32.3125rem) {
    .Inline_Inline__form__OWY2M {
      flex: 2 1;
    }
  }
  @media (max-width: 32.25rem) {
    .Inline_Inline__image__OTNmZ {
      width: 100%;
      height: 7.3rem;
      border-radius: 0.375rem 0.375rem 0 0;
      background-position: 50%;
      background-size: cover;
    }
  }
  @media (min-width: 32.3125rem) {
    .Inline_Inline__image__OTNmZ {
      max-width: calc(33.3333% - 0.75rem);
      flex: 1 1;
      border-radius: 0 0.375rem 0.375rem 0;
      background-position: 100%;
      background-size: cover;
    }
  }
`;
