import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Services from '../components/Services';
import About from '../components/About';
import ServicesComp from '../components/ServicesComp';

const ServicesPage = () => {
  return (
    <>
      <Hero black />
      <ServicesComp />
      <Footer />
    </>
  );
};

export default ServicesPage;
