import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

const Homepage = () => {
  return (
    <>
      <Hero />
      <Header />
      <Footer />
    </>
  );
};

export default Homepage;
