import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Aboutus from './pages/Aboutus';
import Services from './pages/Services';
import ServicesPage from './pages/Services';
import ContactPage from './pages/Contact';

const Routes = () => {
  return (
    <Switch>
      <Route
        path='/'
        exact
        render={(props) => {
          return <Homepage {...props} />;
        }}
      />
      <Route
        path='/about'
        exact
        render={(props) => {
          return <Aboutus {...props} />;
        }}
      />
      <Route
        path='/services'
        exact
        render={(props) => {
          return <ServicesPage {...props} />;
        }}
      />
      <Route
        path='/contact'
        exact
        render={(props) => {
          return <ContactPage {...props} />;
        }}
      />
    </Switch>
  );
};

export default Routes;
