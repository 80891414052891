import Alert from './components/Alert';
import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './TopScroll';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './slices/index';

function App() {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ScrollToTop />
        <Alert />
        <Routes />
      </Router>
    </PersistGate>
  );
}

export default App;
