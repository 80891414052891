import { useState } from 'react';
import { faq } from './faqs';
import * as S from './styles';

const Hero = ({ black }) => {
  const [openNavbar, setOpenNavbar] = useState(false);
  return (
    <>
      <header
        className='site-header header-main-layout-1 ast-primary-menu-enabled ast-builder-menu-toggle-icon ast-mobile-header-inline'
        id='masthead'
        itemType='https://schema.org/WPHeader'
        itemScope='itemscope'
        itemID='#masthead'
        style={{ background: black ? '#212121' : 'inherit' }}>
        <div id='ast-desktop-header' data-toggle-type='dropdown'>
          <div className='ast-main-header-wrap main-header-bar-wrap '>
            <div
              className='ast-primary-header-bar ast-primary-header main-header-bar site-header-focus-item'
              data-section='section-primary-header-builder'>
              <div
                className='site-primary-header-wrap ast-builder-grid-row-container site-header-focus-item ast-container'
                data-section='section-primary-header-builder'>
                <div className='ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center'>
                  <div className='site-header-primary-section-left site-header-section ast-flex site-header-section-left'>
                    <div
                      className='ast-builder-layout-element ast-flex site-header-focus-item'
                      data-section='title_tagline'>
                      <div
                        className='site-branding ast-site-identity'
                        itemType='https://schema.org/Organization'
                        itemScope='itemscope'>
                        <span className='site-logo-img'>
                          <a
                            href='/'
                            className='custom-logo-link transparent-custom-logo'
                            rel='home'
                            itemProp='url'
                            aria-label='FASSIS - SARA BARNA'>
                            <img
                              width={250}
                              height={136}
                              src='/img/logo_new.png'
                              className='custom-logo'
                              alt=''
                              decoding='async'
                              sizes='(max-width: 150px) 100vw, 150px'
                            />
                          </a>
                          <a
                            href='/'
                            className='custom-logo-link ast-transparent-mobile-logo'
                            rel='home'
                            itemProp='url'>
                            <img
                              width={150}
                              height={76}
                              src='/img/logo_new.png'
                              className='custom-logo'
                              alt=''
                              decoding='async'
                              sizes='(max-width: 150px) 100vw, 150px'
                            />
                          </a>
                        </span>{' '}
                      </div>
                      {/* .site-branding */}
                    </div>
                  </div>
                  <div className='site-header-primary-section-right site-header-section ast-flex ast-grid-right-section'>
                    <div
                      className='ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item'
                      data-section='section-hb-menu-1'>
                      <div className='ast-main-header-bar-alignment'>
                        <div className='main-header-bar-navigation'>
                          <nav
                            className='site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item'
                            id='primary-site-navigation-desktop'
                            aria-label='Site Navigation: Menu'
                            itemType='https://schema.org/SiteNavigationElement'
                            itemScope='itemscope'>
                            <div className='main-navigation ast-inline-flex'>
                              <ul
                                id='ast-hf-menu-1'
                                className='main-header-menu ast-menu-shadow ast-nav-menu ast-flex  submenu-with-border stack-on-mobile'>
                                <li
                                  id='menu-item-544'
                                  className='menu-item menu-item-type-post_type menu-item-object-page menu-item-544'>
                                  <a href='/about' className='menu-link'>
                                    O Mnie
                                  </a>
                                </li>
                                <li
                                  id='menu-item-543'
                                  className='menu-item menu-item-type-post_type menu-item-object-page menu-item-543'>
                                  <a href='/services' className='menu-link'>
                                    Usługi
                                  </a>
                                </li>
                                <li
                                  id='menu-item-543'
                                  className='menu-item menu-item-type-post_type menu-item-object-page menu-item-543'>
                                  <a href='/contact' className='menu-link'>
                                    Skontaktuj się z nami
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </nav>
                        </div>
                      </div>{' '}
                    </div>
                    <div
                      className='ast-builder-layout-element ast-flex site-header-focus-item ast-header-button-1'
                      data-section='section-hb-button-1'>
                      <div className='ast-builder-button-wrap ast-builder-button-size-sm'>
                        <a
                          className='ast-custom-button-link'
                          href='/contact'
                          target='_self'>
                          <div className='ast-custom-button'>
                            Skontaktuj się z nami
                          </div>
                        </a>
                        <a className='menu-link' href='/contact' target='_self'>
                          Skontaktuj się z nami
                        </a>
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
        {/* Main Header Bar Wrap */}
        <div
          id='ast-mobile-header'
          className='ast-mobile-header-wrap '
          data-type='dropdown'>
          <div className='ast-main-header-wrap main-header-bar-wrap'>
            <div
              className='ast-primary-header-bar ast-primary-header main-header-bar site-primary-header-wrap site-header-focus-item ast-builder-grid-row-layout-default ast-builder-grid-row-tablet-layout-default ast-builder-grid-row-mobile-layout-default'
              data-section='section-transparent-header'>
              <div className='ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center'>
                <div className='site-header-primary-section-left site-header-section ast-flex site-header-section-left'>
                  <div
                    className='ast-builder-layout-element ast-flex site-header-focus-item'
                    data-section='title_tagline'>
                    <div
                      className='site-branding ast-site-identity'
                      itemType='https://schema.org/Organization'
                      itemScope='itemscope'>
                      <span className='site-logo-img'>
                        <a
                          href='/'
                          className='custom-logo-link transparent-custom-logo'
                          rel='home'
                          itemProp='url'
                          aria-label='FASSIS - SARA BARNA'>
                          <img
                            width={150}
                            height={76}
                            src='/img/logo_new.png'
                            className='custom-logo'
                            alt=''
                            decoding='async'
                            sizes='(max-width: 150px) 100vw, 150px'
                          />
                        </a>
                        <a
                          href='/'
                          className='custom-logo-link ast-transparent-mobile-logo'
                          rel='home'
                          itemProp='url'>
                          <img
                            width={200}
                            height={76}
                            src='/img/logo_new.png'
                            className='custom-logo'
                            alt=''
                            decoding='async'
                            sizes='(max-width: 150px) 100vw, 150px'
                          />
                        </a>
                      </span>{' '}
                    </div>
                    {/* .site-branding */}
                  </div>
                </div>
                <div className='site-header-primary-section-right site-header-section ast-flex ast-grid-right-section'>
                  <div
                    className='ast-builder-layout-element ast-flex site-header-focus-item'
                    data-section='section-header-mobile-trigger'
                    onClick={() => setOpenNavbar(!openNavbar)}>
                    <div className='ast-button-wrap'>
                      <button
                        type='button'
                        className='menu-toggle main-header-menu-toggle ast-mobile-menu-trigger-fill'
                        aria-expanded='false'
                        data-index={0}>
                        <span className='screen-reader-text'>Main Menu</span>
                        <span className='mobile-menu-toggle-icon'>
                          <span className='ahfb-svg-iconset ast-inline-flex svg-baseline'>
                            <svg
                              className='ast-mobile-svg ast-menu-svg'
                              fill='currentColor'
                              version='1.1'
                              xmlns='http://www.w3.org/2000/svg'
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'>
                              <path d='M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z' />
                            </svg>
                          </span>
                          <span className='ahfb-svg-iconset ast-inline-flex svg-baseline'>
                            <svg
                              className='ast-mobile-svg ast-close-svg'
                              fill='currentColor'
                              version='1.1'
                              xmlns='http://www.w3.org/2000/svg'
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'>
                              <path d='M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z' />
                            </svg>
                          </span>{' '}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='ast-mobile-header-content content-align-flex-start '
            style={{ display: openNavbar ? 'flex' : 'none' }}>
            <div
              className='ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item'
              data-section='section-hb-menu-1'>
              <div className='ast-main-header-bar-alignment'>
                <div className='main-header-bar-navigation' style={{}}>
                  <nav
                    className='site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item'
                    id='primary-site-navigation-mobile'
                    aria-label='Site Navigation: Menu'
                    itemType='https://schema.org/SiteNavigationElement'
                    itemScope='itemscope'>
                    <div className='main-navigation ast-inline-flex'>
                      <ul
                        id='ast-hf-menu-1'
                        className='main-header-menu ast-menu-shadow ast-nav-menu ast-flex  submenu-with-border stack-on-mobile'>
                        <li className='menu-item menu-item-type-post_type menu-item-object-page menu-item-544'>
                          <a href='/about' className='menu-link'>
                            O Mnie
                          </a>
                        </li>
                        <li className='menu-item menu-item-type-post_type menu-item-object-page menu-item-543'>
                          <a href='/services' className='menu-link'>
                            Usługi
                          </a>
                        </li>
                        <li
                          id='menu-item-543'
                          className='menu-item menu-item-type-post_type menu-item-object-page menu-item-543'>
                          <a href='/contact' className='menu-link'>
                            Skontaktuj się z nami
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>{' '}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Hero;
